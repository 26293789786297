



















































































































import Repository from "../Repository"

import { IEventLog } from '../types'

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Jour extends Vue {

  eventslog: Array<IEventLog> = []
  currentPage: number = 0
  perPage: number = 10
  timer:number = 0

  intervalLoadData() {
      this.load();
      this.timer = setInterval(this.load, 2 * 60 * 1000 * 60) // every 2 minutes
    }

    load() {
      Repository.get("events/log?stm=timer").then(
        res => (this.eventslog = res.data)
      )
    }

    fancyDay(inDate: Date):number {
      var d = new Date(inDate)
      return d.getDate()
    }

    fancyDayOfWeek(inDate: Date): string {
      var d = new Date(inDate)
      return d.toLocaleString("en-us", { weekday: "short" })
    }

    fancyMonthYear(inDate: Date): string {
      var d = new Date(inDate)
      return (
        d.toLocaleString("en-us", { month: "short" }) +
        " " +
        d.getFullYear().toString()
      )
    }

    fancyHM(inDate: Date): string {
      var d = new Date(inDate)
      return d.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "2-digit"
      })
    }

    fancyTime(inDate: Date): string {
      var d = new Date(inDate)
      return d.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit"
      })
    }


  get eventslogFiltered(): Array<IEventLog> {
      return this.eventslog.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    }


  mounted() {

    Repository.get("touch")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

    this.intervalLoadData()
  }
}
