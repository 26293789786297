




































































































































































































































































































































































































































































































































































































































































































































































import Repository from "../Repository"

import { ProfileType, Recur, RecurType, EventType, IRecurringType, NotificationType } from '../types'

import { Vue, Component, Prop } from 'vue-property-decorator'

type keyType = "sms" | "phone" | "email"

@Component
export default class Event extends Vue {

  // eslint-disable
  @Prop(String) 
  recurringtypeid: Recur;
  // eslint-enable

  isSubmitted = false

  spacer = " " // hack to get around vuejs getting rid of &nbsp;
  redirect: boolean = true

  available:number | null = null
  maxfutureevents: number | null = null

  timer: 0
  showprogress = false
  max = 100
  value = 10

  // start data
  recur: RecurType = new RecurType()

  event: EventType = new EventType()

  profile: ProfileType = new ProfileType()

      // lining up array to be like CF
  NE_mode: string = "basic"

  N_basic: NotificationType = new NotificationType()
  N_basic_indeterminate: NotificationType = new NotificationType()
  E_basic: NotificationType = new NotificationType()
  E_basic_indeterminate: NotificationType = new NotificationType()


  N_disabled = { "sms" : false, "phone" : false, "email" : false }

  E_disabled = { "sms" : false, "phone" : false, "email" : false }


  mindate: string = "" // It needs to be in a specific format
  lastdate: Date | null = null
  firstdate: Date | null = null
  datestate: boolean | null = null


  recurringtypes: Array<IRecurringType> = []
  timezones = []
  halfhours = []
  dayofmonth = Array.from({ length: 31 }, (_, i) => i+1)


  dtitles: Array<string> = []

  monthlyoptions = [
        { value: 1, text: "First" },
        { value: 2, text: "Second" },
        { value: 3, text: "Third" },
        { value: 4, text: "Fourth" },
        { value: -1, text: "Last" }
      ]

  dayoptions = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

  intervals = [
        { value: 1, text: "Everyday" },
        { value: 0, text: "Every weekday" },
        {
          label: "Every",
          options: [
            { value: 2, text: "2 days" },
            { value: 3, text: "3 days" },
            { value: 4, text: "4 days" },
            { value: 5, text: "5 days" },
            { value: 6, text: "6 days" },
            { value: 7, text: "7 days" }
          ]
        }
    ]

  intervalsMonthly = [
        { value: 1, text: "Every month" },
        { value: 2, text: "Every 2 months" },
        { value: 3, text: "Every 3 months" },
        { value: 4, text: "Every 4 months" },
        { value: 5, text: "Every 5 months" },
        { value: 6, text: "Every 6 months" },
        { value: 7, text: "Every 7 months" },
        { value: 8, text: "Every 8 months" },
        { value: 9, text: "Every 9 months" },
        { value: 10, text: "Every 10 months" },
        { value: 11, text: "Every 11 months" },
        { value: 12, text: "Every 12 months" }
      ]

    // Checkbox management
    doN_basic(key: keyType) {
      console.log("Doing N_basic");
      // This triggers before field is updated. That is why it looks backward
      // Apparently the Bootstrap Vue people now do it forward
      if (this.NE_mode == "basic" && this.N_basic[key]) {
        this.event.N[0][key] = true;
        this.event.N[1][key] = true;
        this.event.N[2][key] = true;
        this.event.N[3][key] = true;
      }
      if (this.NE_mode == "basic" && !this.N_basic[key]) {
        this.event.N[0][key] = false;
        this.event.N[1][key] = false;
        this.event.N[2][key] = false;
        this.event.N[3][key] = false;
      }
    }

    doE_basic(key: keyType) {
      console.log("Doing E_basic");
      // This triggers before field is updated. That is why it looks backward
      if (this.NE_mode == "basic" && !this.E_basic[key]) {
        this.event.E[2][key] = true
        this.event.E[3][key] = true
      }
      if (this.NE_mode == "basic" && this.E_basic[key]) {
        this.event.E[2][key] = false
        this.event.E[3][key] = false
      }
    }

    doSwitchToBasic() {
      this.NE_mode = "basic"

      console.log("Switching to basic")

      // Normal
      this.N_basic.sms = false
    
      if (
        this.event.N[0].sms || 
        this.event.N[1].sms || 
        this.event.N[2].sms || 
        this.event.N[3].sms
      ) {
        this.N_basic_indeterminate.sms = true
      }
      if (
        this.event.N[0].sms && 
        this.event.N[1].sms && 
        this.event.N[2].sms && 
        this.event.N[3].sms
      ) {
        this.N_basic.sms = true
        this.N_basic_indeterminate.sms = false
      }

      this.N_basic.phone = false
      if (
        this.event.N[0].phone ||
        this.event.N[1].phone ||
        this.event.N[2].phone ||
        this.event.N[3].phone
      ) {
        this.N_basic_indeterminate.phone = true
      }
      if (
        this.event.N[0].phone &&
        this.event.N[1].phone &&
        this.event.N[2].phone &&
        this.event.N[3].phone
      ) {
        this.N_basic.phone = true
        this.N_basic_indeterminate.phone = false
      }

      this.N_basic.email = false
      if (
        this.event.N[0].email ||
        this.event.N[1].email ||
        this.event.N[2].email ||
        this.event.N[3].email
      ) {
        this.N_basic_indeterminate.email = true
      }
      if (
        this.event.N[0].email &&
        this.event.N[1].email &&
        this.event.N[2].email &&
        this.event.N[3].email
      ) {
        this.N_basic.email = true
        this.N_basic_indeterminate.email = false
      }

      // Emergency
      this.E_basic.sms = false;
      if (this.event.E[2].sms || this.event.E[3].sms) {
        this.E_basic_indeterminate.sms = true
      }
      if (this.event.E[2].sms && this.event.E[3].sms) {
        this.E_basic.sms = true
        this.E_basic_indeterminate.sms = false
      }

      this.E_basic.phone = false;
      if (this.event.E[2].phone || this.event.E[3].phone) {
        this.E_basic_indeterminate.phone = true
      }
      if (this.event.E[2].phone && this.event.E[3].phone) {
        this.E_basic.phone = true;
        this.E_basic_indeterminate.phone = false
      }

      this.E_basic.email = false;
      if (this.event.E[2].email || this.event.E[3].email) {
        this.E_basic_indeterminate.email = true
      }
      if (this.event.E[2].email && this.event.E[3].email) {
        this.E_basic.email = true
        this.E_basic_indeterminate.email = false
      }
    }


    doSwitchToAdvanced() {
      this.NE_mode = "advanced"
    }

    startProgress() {

    }


    // AJAX
    doEvent() {

      this.isSubmitted = true

      if (this.event.title.length < 3) return
      if (this.eventoptions.details.mode == 'required' && this.event.details.length < 3) return
      if (this.eventoptions.location.mode == 'required' && this.event.location.length < 3) return
      if (this.eventoptions.website.mode == 'required' && this.event.website.length < 3) return


      if (
        this.event.recur.id != "One-time" &&
        (typeof this.event.recur.until == "undefined" || this.event.recur.until === null )
      ) {
        this.datestate = false
        return
      }
      this.datestate = true // Invisible on single

      if (this.event.recur.id == "Weekly" && !this.event.recur.byDay.some(x => x)) {
        this.$bvToast.toast("You must chose at least one day. If you chose exactly one, it must match the start date", {
          variant: "danger",
          title: "Error"
        })
        return
      }

      if (this.event.recur.id == "Weekly" && !this.weeklyState) {
        this.$bvToast.toast("You must chose the day that matches your Expected Return", {
          variant: "danger",
          title: "Error"
        })
        return
      }

      if (
        this.event.recur.id == "Monthly" &&
        this.event.recur.mode == "advanced" &&
        (typeof this.event.recur.byDayPre == "undefined" ||
          typeof this.event.recur.dayOfWeek == "undefined")
      ) {
        this.$bvToast.toast("You must chose at least one day of the month", {
          variant: "danger",
          title: "Error"
        })
        return
      }

      this.startTimer()

      return Repository.post("events", this.event).then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.stopTimer()

        if (res.data.options.variant == "success") {
          // Only admins can stop the redirect
          if (this.redirect) {
            this.$router.push({ path: '/events'})
            }
          this.$bvToast.toast(res.data.content, res.data.options)
        }
        else {
          this.$bvToast.toast(res.data.content, res.data.options)
        }
      })
    }

    startTimer() {
      this.showprogress = true
      let vm = this
      let timer = setInterval(function() {
        vm.value++
        if (vm.value >= 100) clearInterval(timer)
      }, 200)
    }

    stopTimer() {
      this.showprogress = false
      this.value = 0
    }


    doContactChange() {
      for (let contact of this.profile.contacts) {
        if (contact.id === this.event.contactid) {

          this.event.E[2].sms = typeof contact.sms !== "undefined"
          this.event.E[2].phone = typeof contact.phone !== "undefined"
          this.event.E[2].email = typeof contact.email !== "undefined"

          this.event.E[3].sms = typeof contact.sms !== "undefined"
          this.event.E[3].phone = typeof contact.phone !== "undefined"
          this.event.E[3].email = typeof contact.email !== "undefined"

          this.E_basic.sms = typeof contact.sms !== "undefined"
          this.E_basic.phone = typeof contact.phone !== "undefined"
          this.E_basic.email = typeof contact.email !== "undefined"

          this.E_disabled = { 
            "sms"   : typeof contact.sms    == "undefined",
            "phone" : typeof contact.phone  == "undefined",
            "email" : typeof contact.email  == "undefined"
            }
        }
      }
    }

    getProfile() {

      Repository.get("profile").then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.profile = res.data
        this.event.contactid = this.profile.contacts[0].id // consider making favorites work
        this.doContactChange()

        this.event.timezoneid = this.profile.timezoneid

        this.event.N[0].sms = this.profile.smspref
        this.event.N[0].phone = this.profile.phonepref
        this.event.N[0].email = this.profile.emailpref

        this.event.N[1].sms = this.profile.smspref
        this.event.N[1].phone = this.profile.phonepref
        this.event.N[1].email = this.profile.emailpref

        this.event.N[2].sms = this.profile.smspref
        this.event.N[2].phone = this.profile.phonepref
        this.event.N[2].email = this.profile.emailpref

        this.event.N[3].sms = this.profile.smspref
        this.event.N[3].phone = this.profile.phonepref
        this.event.N[3].email = this.profile.emailpref

        if (this.event.N[0].sms && this.event.N[1].sms && this.event.N[2].sms && this.event.N[3].sms) {
          this.N_basic.sms = true
        }
        if (
          this.event.N[0].phone &&
          this.event.N[1].phone &&
          this.event.N[2].phone &&
          this.event.N[3].phone
        ) {
          this.N_basic.phone = true;
        }
        if (
          this.event.N[0].email &&
          this.event.N[1].email &&
          this.event.N[2].email &&
          this.event.N[3].email
        ) {
          this.N_basic.email = true;
        }

        this.N_disabled = { 
          "sms"   : typeof this.profile.sms    == "undefined",
          "phone" : typeof this.profile.phone  == "undefined",
          "email" : typeof this.profile.email  == "undefined"
          }

      })
    }

    gettoday() {
      var today1 = new Date()

      var today = today1.getFullYear() 
        + '-' 
        + (today1.getMonth() < 9 ? '0' : '')
        + (today1.getMonth() + 1) 
        + '-' 
        + today1.getDate()

      return today
    }

    gettomorrow() {
      var tomorrow1 = new Date()
      tomorrow1.setDate(new Date().getDate() + 1)

      var tomorrow = tomorrow1.getFullYear() 
        + '-' 
        + (tomorrow1.getMonth() < 9 ? '0' : '')
        + (tomorrow1.getMonth() + 1) 
        + '-' 
        + tomorrow1.getDate()

      return tomorrow
    }


  get verboseeventlog() {
      return this.$store.getters.pref.verboseeventlog
    }


get eventoptions() {
    return {
      "descriptions" : {                                                "label" : this.$store.getters.pref.descriptions_label  },
      "details"   : { "mode" : this.$store.getters.pref.details_mode,   "label" : this.$store.getters.pref.details_label      },
      "location"  : { "mode" : this.$store.getters.pref.location_mode,  "label" : this.$store.getters.pref.location_label, "defaults" : this.$store.getters.pref.location_defaults },
      "website"   : { "mode" : this.$store.getters.pref.website_mode,   "label" : this.$store.getters.pref.website_label      },
      "grid"      : this.$store.getters.grid
    }
  }

  get noNmethod() {
      return (
        !this.event.N[0].sms &&
        !this.event.N[0].phone &&
        !this.event.N[0].email &&
        !this.event.N[1].sms &&
        !this.event.N[1].phone &&
        !this.event.N[1].email &&
        !this.event.N[2].sms &&
        !this.event.N[2].phone &&
        !this.event.N[2].email &&
        !this.event.N[3].sms &&
        !this.event.N[3].phone &&
        !this.event.N[3].email
      )
    }

    get noEmethod() {
      return (
        this.event.contactid !== null &&
        !this.event.E[2].sms &&
        !this.event.E[2].phone &&
        !this.event.E[2].email &&
        !this.event.E[3].sms &&
        !this.event.E[3].phone &&
        !this.event.E[3].email
      )
    }

    get recurringtype() {
      for (var item of this.recurringtypes) {
        if (item.id == this.recurringtypeid) {
          return item
        }
      }
      return {}
    }

    // states
    get titleState()  {

      if (this.isSubmitted && this.event.title.length < 1)  {
        return false
      }

      return this.event.title.length > 0 ? true : null
    }


    get detailsState()  {
      if (this.eventoptions.details.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.details.length < 1)  {
        return false
      }

      return this.event.details.length > 0 ? true : null
    }


    get locationState()  {
      if (this.eventoptions.location.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.location.length < 1)  {
        return false
      }

      return this.event.location.length > 0 ? true : null
    }


    get websiteState()  {
      if (this.eventoptions.website.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.website.length < 1)  {
        return false
      }

      return this.event.website.length > 0 ? true : null
    }

    // see: https://stackoverflow.com/questions/5619202/converting-a-string-to-a-date-in-javascript

    get weeklyState() : boolean | null {

      // none are checked
      if (!this.event.recur.byDay.some(x => x)) return null

      // one is checked, but it is wrong day
      let parts : Array<string> = this.event.startdate.split("-")
      let startDate = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]))
      if (!this.event.recur.byDay[startDate.getDay()]) return false

      return true
    }

    get isAdmin(): boolean {
      return this.$store.getters.isAdmin
    }


  mounted() {

    this.mindate = this.gettoday()
    this.event.startdate = this.gettomorrow()

    // a long time ago, I would tinker with the field on the left to do editing.
    this.event.recur.id = this.recurringtypeid


    Repository.get("events/newdefaults")
      .then(res => {
        this.firstdate = res.data.firstdate
        this.event.startdate = res.data.startdate
        this.event.starttime = res.data.starttime
        this.lastdate = res.data.lastdate

        this.available = res.data.available
        this.maxfutureevents = res.data.maxfutureevents
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })



    Repository.get("recurringtypes").then(
      res => { this.recurringtypes = res.data }
      )

    Repository.get("events/descriptions").then(
      res => (this.dtitles = res.data)
    )

    Repository.get("halfhours").then(
      res => (this.halfhours = res.data)
    )

    Repository.get("timezone").then(
      res => (this.timezones = res.data)
    )

    this.getProfile()
  }
}
