
export class ProfileType {
	contacts: Array<IContact> = []
	email : string = ''
	emailpref: boolean = false
	expirationdate: Date | null = null
	id: number = 0
	isexpired: boolean = false
	isrenewable: boolean = false
	name: string = ''
	partnerdate: Date | null = null
	partnerid: number | null = null
	phone: string | null = null
	phonemachine: boolean = false
	phonepref: boolean = false
	pollymedia: string = ''
	roles: string[] = []
	sms: string | null = null
	smspref: boolean = false
	stateprovinceid: string = ""
	timezoneid: number = 0
}


export class ContactUsType {
	name: string = ""
	phone: string = ""
	from: string = ""
	currentCustomer: string = "No"
	subject: string = ""
	body: string = ""
	image: any = ""
	image_name: string = ""
}


export class NotificationType {
	sms : boolean = false
	phone: boolean = false
	email : boolean = false
}


export type Recur = "One-time" | "Daily" | "Monthly" | "Weekly" | "Yearly"


export class RecurType {
	id: Recur = "One-time" 
	interval: number = 1
	byDay: Array<boolean> = [false, false, false, false, false, false, false]
	byDayPre: string | null = null
	mode: string = "simple"
	dayOfWeek: number | null = null
	dayOfMonth: number = 1
	until: Date | null = null
   }

// This intended for single event management. Don't make an array of these
export class EventType {
	id : number | null = null
	title : string = ""
	details : string = ""
	location : string = ""
	website : string = ""
	startdate : string = "" // Please submit string. JS dates have UTC and hour minute second
	starttime : string | null = null
	contactid : number | null = null
	timezoneid: number | null = null
	N : Array<NotificationType> = [
		new NotificationType(),
		new NotificationType(),
		new NotificationType(),
		new NotificationType()
	]
	E : Array<NotificationType> = [
		new NotificationType(),
		new NotificationType(),
		new NotificationType(),
		new NotificationType()
	]
	recur: RecurType = new RecurType()
}


export class toastOptionsType {
	variant : string = ""
	title : string = ""
}


export interface IContact {
	id: number,
	title: string,
	name: string,
	sms: string,
	phone: string,
	email: string,
}



export interface IPet {
	title : string,
	icon : string,
	count : number | null,
}


export interface IRecurringType {
	id : string,
	badgeletter: string,
	description : string,
}


export class ILoginResponse {
	roles: string[]
	content: string
	options: toastOptionsType
	token: string
	isPasshashReset: boolean = false
}


export interface IEventLog {
	_rowVariant : string,
	startdate : Date
	delay : number
	startdatewithdelay : Date,
	timeoofdayicon : string,
	title: string,
	eventid: number,
	callbackstatus: string,
	isem: boolean
}


export interface IUpcoming {
	id: number,
	alerttypeid: number,
	title: string,
	startdate: Date,
	delay: number,
	startdatewithdelay: Date
}

export class SquareType {
	square_script : string = ""
	square_source_id: string = ""
	applicationid : string = ""
}
