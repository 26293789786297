
































































































































































































































































































































































































































import Repository from "../Repository"

import { ProfileType } from '../types'

import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class Events extends Vue {

  @Prop(String) selected!: string

  profile: ProfileType = new ProfileType()

  readonly spacer:string =  " " // hack to get around vuejs getting rid of &nbsp;

   // start data
  recurringtype = { id: null, description: null }

//  recur = { interval: null, byDay: [0, 0, 0, 0, 0, 0, 0] }

  pastevents = []

  timer:number = 0

  @Watch('selected')
  onSelectedChanged() { this.load() }

  load() {

      if (this.selected == "Current") {
        // I don't know when the data will come, but I do know it will not be immediate
        this.$store.dispatch("events")
        return
      }
      

      Repository.get("/events/past" + "?stm=timer").then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.pastevents = res.data.events
      })
  }

  doDelay(id: number, delayhour: number) {
      return Repository.post("events/" + id + "/delay?hour=" + delayhour).then(
        res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$bvToast.toast(res.data.content, res.data.options)
          this.load()
        }
      )
    }

  doEdit(id: number) {
      this.$router.push({ path: "/event/" + id })
    }

  doCancel(id: number) {
      return Repository.delete("events/" + id).then(res => {
        this.$bvToast.toast(res.data.content, res.data.options)
        this.load()
      })
    }

  doDelete(id: number) {
      return Repository.delete("events/" + id).then(res => {
        this.$bvToast.toast(res.data.content, res.data.options)
        this.load()
      })
    }

    doDeleteSeries(id: number) {
      return Repository.delete("events/" + id + "/series").then(res => {
        this.$bvToast.toast(res.data.content, res.data.options)
        this.load()
      })
    }

    fancyDay(inDate: Date) {
      var d = new Date(inDate)
      return d.getDate()
    }

    fancyDayOfWeek(inDate: Date) {
      var d = new Date(inDate)
      return d.toLocaleString("en-us", { weekday: "short" })
    }

    fancyMonthYear(inDate: Date) {
      var d = new Date(inDate)
      return (
        d.toLocaleString("en-us", { month: "short" }) +
        " " +
        d.getFullYear().toString()
      )
    }

    fancyTime(inDate: Date) {
      var d = new Date(inDate);
      return d.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "2-digit"
      })
    }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }


  get verboseeventlog() {
      return this.$store.getters.pref.verboseeventlog
    }


  get events()  {
    if (this.selected == "Current") {
      return this.$store.getters.events
    }

    return this.pastevents
  }

  mounted() {

    Repository.get("profile")
      .then(res => {

        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.profile = res.data;
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

    // could be doing restore in the background
    if (!this.$store.getters.isEvent && !this.$store.getters.isRestoring ) {
      console.log("Mounted bug isEvent: " + this.$store.getters.isEvent + " isRestoring: " + this.$store.getters.isRestoring)
      // I this is happening too soon
    //  this.$router.push({ path: "/profile" })
    }

    if (this.$store.getters.message != "") {
        this.$bvToast.toast(
        this.$store.getters.message,
        this.$store.getters.messageOptions
        )
    }

    this.load()
    // not expected to necessarily run on iOS
    this.timer = setInterval(this.load, 10 * 60 * 1000) // every 10 minutes

    document.addEventListener("resume", this.load)
  }

  beforeDestory() {
    clearInterval(this.timer)
  }
}
