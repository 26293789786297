









































































































































































































































































































































































































































































































































import Repository from "../Repository"

import { ProfileType, RecurType, EventType, IRecurringType, NotificationType } from '../types'

import { Vue, Component  } from 'vue-property-decorator'

type keyType = "sms" | "phone" | "email"

@Component
export default class Event extends Vue {

  id: number 

  isSubmitted = false


  spacer = " " // hack to get around vuejs getting rid of &nbsp;


  // start data
  recur: RecurType = new RecurType()

  event: EventType = new EventType()

  profile: ProfileType = new ProfileType()

  redirect: boolean = true

      // lining up array to be like CF
  NE_mode: string = "basic"
  lastdate: Date | null = null
  firstdate: Date | null = null


  N_basic: NotificationType = new NotificationType()
  N_basic_indeterminate: NotificationType = new NotificationType()
  E_basic: NotificationType = new NotificationType()
  E_basic_indeterminate: NotificationType = new NotificationType()

  Nsms_disabled = false
  Nphone_disabled = false
  Nemail_disabled = false

  Esms_disabled = false
  Ephone_disabled = false
  Eemail_disabled = false

  laststartdate = null


  recurringtypes: Array<IRecurringType> = []
  timezones = []
  halfhours = []


  dtitles: Array<string> = []

  monthlyoptions = [
        { value: 1, text: "First" },
        { value: 2, text: "Second" },
        { value: 3, text: "Third" },
        { value: 4, text: "Fourth" },
        { value: -1, text: "Last" }
      ]

  dayoptions = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

  intervals = [
        { value: 1, text: "Everyday" },
        { value: 0, text: "Every weekday" },
        {
          label: "Every",
          options: [
            { value: 2, text: "2 days" },
            { value: 3, text: "3 days" },
            { value: 4, text: "4 days" },
            { value: 5, text: "5 days" },
            { value: 6, text: "6 days" },
            { value: 7, text: "7 days" }
          ]
        }
    ]

  intervalsMonthly = [
        { value: 1, text: "Every month" },
        { value: 2, text: "Every 2 months" },
        { value: 3, text: "Every 3 months" },
        { value: 4, text: "Every 4 months" },
        { value: 5, text: "Every 5 months" },
        { value: 6, text: "Every 6 months" },
        { value: 7, text: "Every 7 months" },
        { value: 8, text: "Every 8 months" },
        { value: 9, text: "Every 9 months" },
        { value: 10, text: "Every 10 months" },
        { value: 11, text: "Every 11 months" },
        { value: 12, text: "Every 12 months" }
      ]

    // Checkbox management
    doN_basic(key: keyType) {
      console.log("Doing N_basic");
      // This triggers before field is updated. That is why it looks backward
      if (this.NE_mode == "basic" && !this.N_basic[key]) {
        this.event.N[0][key] = true;
        this.event.N[1][key] = true;
        this.event.N[2][key] = true;
        this.event.N[3][key] = true;
      }
      if (this.NE_mode == "basic" && this.N_basic[key]) {
        this.event.N[0][key] = false;
        this.event.N[1][key] = false;
        this.event.N[2][key] = false;
        this.event.N[3][key] = false;
      }
    }

    doE_basic(key: keyType) {
      console.log("Doing E_basic");
      // This triggers before field is updated. That is why it looks backward
      if (this.NE_mode == "basic" && !this.E_basic[key]) {
        this.event.E[2][key] = true
        this.event.E[3][key] = true
      }
      if (this.NE_mode == "basic" && this.E_basic[key]) {
        this.event.E[2][key] = false
        this.event.E[3][key] = false
      }
    }

    doSwitchToBasic() {
      this.NE_mode = "basic"

      console.log("Switching to basic")

      // Normal
      this.N_basic.sms = false
      if (this.event.N[0].sms || this.event.N[1].sms || this.event.N[2].sms || this.event.N[3].sms) {
        this.N_basic_indeterminate.sms == true
      }
      if (this.event.N[0].sms && this.event.N[1].sms && this.event.N[2].sms && this.event.N[3].sms) {
        this.N_basic.sms = true
        this.N_basic_indeterminate.sms == false
      }

      this.N_basic.phone = false
      if (
        this.event.N[0].phone ||
        this.event.N[1].phone ||
        this.event.N[2].phone ||
        this.event.N[3].phone
      ) {
        this.N_basic_indeterminate.phone = true
      }
      if (
        this.event.N[0].phone &&
        this.event.N[1].phone &&
        this.event.N[2].phone &&
        this.event.N[3].phone
      ) {
        this.N_basic.phone = true
        this.N_basic_indeterminate.phone = false
      }

      this.N_basic.email = false
      if (
        this.event.N[0].email ||
        this.event.N[1].email ||
        this.event.N[2].email ||
        this.event.N[3].email
      ) {
        this.N_basic.email = false
        this.N_basic_indeterminate.email = true
      }
      if (
        this.event.N[0].email &&
        this.event.N[1].email &&
        this.event.N[2].email &&
        this.event.N[3].email
      ) {
        this.N_basic.email = true
        this.N_basic_indeterminate.email = false
      }

      // Emergency
      this.E_basic.sms = false;
      if (this.event.E[2].sms || this.event.E[3].sms) {
        this.E_basic_indeterminate.sms == true
      }
      if (this.event.E[2].sms && this.event.E[3].sms) {
        this.E_basic.sms = true
        this.E_basic_indeterminate.sms == false
      }

      this.E_basic.phone = false;
      if (this.event.E[2].phone || this.event.E[3].phone) {
        this.E_basic_indeterminate.phone = true
      }
      if (this.event.E[2].phone && this.event.E[3].phone) {
        this.E_basic.phone = true;
        this.E_basic_indeterminate.phone = false
      }

      this.E_basic.email = false;
      if (this.event.E[2].email || this.event.E[3].email) {
        this.E_basic_indeterminate.email = true
      }
      if (this.event.E[2].email && this.event.E[3].email) {
        this.E_basic.email = true
        this.E_basic_indeterminate.email = false
      }
    }


    doSwitchToAdvanced() {

      this.NE_mode = "advanced"
    }

    // AJAX
    doEvent() {

      console.log(JSON.stringify(this.event.recur))

      this.isSubmitted = true

      if (this.event.title.length < 3) return
      if (this.eventoptions.details.mode == 'required' && this.event.details.length < 3) return
      if (this.eventoptions.location.mode == 'required' && this.event.location.length < 3) return
      if (this.eventoptions.website.mode == 'required' && this.event.website.length < 3) return



      console.log("We are committing an edit")

      return Repository.put(`events/${this.id}`, this.event).then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        if (res.data.options.variant == "success") {
          // Only admins can stop the redirect
          if (this.redirect) {

            this.$store.dispatch("message_nextpage", res.data)

            this.$router.push({ path: '/events'})
            }
          this.$bvToast.toast(res.data.content, res.data.options)
        }
        else {
          this.$bvToast.toast(res.data.content, res.data.options)
        }
      })


    }

    doContactChange() {
      for (let contact of this.profile.contacts) {
        if (contact.id === this.event.contactid) {

          this.Esms_disabled = typeof contact.sms == "undefined"
          this.Ephone_disabled = typeof contact.phone == "undefined"
          this.Eemail_disabled = typeof contact.email == "undefined"
        }
      }
    }

    getProfile() {

      Repository.get("profile").then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.profile = res.data
        this.event.contactid = this.profile.contacts[0].id // consider making favorites work
        this.doContactChange()

        this.event.timezoneid = this.profile.timezoneid


        this.Nsms_disabled    = typeof this.profile.sms == "undefined"
        this.Nphone_disabled  = typeof this.profile.phone == "undefined"
        this.Nemail_disabled  = typeof this.profile.email == "undefined"
      })
    }

    gettomorrow() {
      var tomorrow1 = new Date()
      tomorrow1.setDate(new Date().getDate() + 1)

      var tomorrow = tomorrow1.getFullYear() + '-' + (tomorrow1.getMonth() + 1) + '-' + tomorrow1.getDate()

      return tomorrow
    }


  get verboseeventlog() {
      return this.$store.getters.pref.verboseeventlog
    }

  get eventoptions() {
    return {
      "descriptions" : {                                                "label" : this.$store.getters.pref.descriptions_label  },
      "details"   : { "mode" : this.$store.getters.pref.details_mode,   "label" : this.$store.getters.pref.details_label      },
      "location"  : { "mode" : this.$store.getters.pref.location_mode,  "label" : this.$store.getters.pref.location_label, "defaults" : this.$store.getters.pref.location_defaults },
      "website"   : { "mode" : this.$store.getters.pref.website_mode,   "label" : this.$store.getters.pref.website_label      }
    }
  }

  get noNmethod() {
      return (
        !this.event.N[0].sms &&
        !this.event.N[0].phone &&
        !this.event.N[0].email &&
        !this.event.N[1].sms &&
        !this.event.N[1].phone &&
        !this.event.N[1].email &&
        !this.event.N[2].sms &&
        !this.event.N[2].phone &&
        !this.event.N[2].email &&
        !this.event.N[3].sms &&
        !this.event.N[3].phone &&
        !this.event.N[3].email
      )
    }

  get noEmethod() {
      return (
        this.event.contactid !== null &&
        !this.event.E[2].sms &&
        !this.event.E[2].phone &&
        !this.event.E[2].email &&
        !this.event.E[3].sms &&
        !this.event.E[3].phone &&
        !this.event.E[3].email
      )
    }

    get recurringtype() {
      for (var item of this.recurringtypes) {
        if (item.id == this.event.recur.id) {
          return item
        }
      }
      return {}
    }

    // states
    get titleState()  {

      if (this.isSubmitted && this.event.title.length < 1)  {
        return false
      }

      return this.event.title.length > 0 ? true : null
    }


    get detailsState()  {
      if (this.eventoptions.details.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.details.length < 1)  {
        return false
      }

      return this.event.details.length > 0 ? true : null
    }


    get locationState()  {
      if (this.eventoptions.location.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.location.length < 1)  {
        return false
      }

      return this.event.location.length > 0 ? true : null
    }


    get websiteState()  {
      if (this.eventoptions.website.mode != 'required') {
        return null
      }

      if (this.isSubmitted && this.event.website.length < 1)  {
        return false
      }

      return this.event.website.length > 0 ? true : null
    }

    get isAdmin(): boolean {
      return this.$store.getters.isAdmin
    }


  mounted() {


    this.id = Number(this.$route.params.id)

    Repository.get(`events/${this.id}`)
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.event = res.data
        this.event.id = this.id // not sure I like this

        if (this.event.details == null)  this.event.details = "" 
        if (this.event.location == null) this.event.location = "" 
        if (this.event.website == null)  this.event.website = "" 

        this.doSwitchToBasic()

        console.log("I have data")
      })
    .catch(() => {
        this.$router.push({ path: "/dead" })
      })


    this.event.startdate = this.gettomorrow()


    Repository.get("events/newdefaults")
      .then(res => {
        this.firstdate = res.data.firstdate
        this.lastdate = res.data.lastdate
        }
    )

    Repository.get("recurringtypes").then(
      res => (this.recurringtypes = res.data)
    )

    Repository.get("events/descriptions").then(
      res => (this.dtitles = res.data)
    )

    Repository.get("halfhours").then(
      res => (this.halfhours = res.data)
    )

    Repository.get("timezone").then(
      res => (this.timezones = res.data)
    )

    this.getProfile()
  }
}
